import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'NORI',
    tokenAddress: '0x4E8884E6237dDF4fEA786d16C08d934beDe05160',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x4E8884E6237dDF4fEA786d16C08d934beDe05160',  // token address
    contractAddress: {
      97: '',
      56: '0x84Ae42c1E037fA1F9ac91a75aa8E4f143C13e6fB',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
