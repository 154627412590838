import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'NORI',
    lpAddresses: {
      97: '',
      56: '0x4E8884E6237dDF4fEA786d16C08d934beDe05160',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x4E8884E6237dDF4fEA786d16C08d934beDe05160',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'NORI-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3f6d7d342A4A45a5608fa01833EC37B3C849a6A2',   // lp address token-bnb
    },
    tokenSymbol: 'NORI',
    tokenAddresses: {
      97: '',
      56: '0x4E8884E6237dDF4fEA786d16C08d934beDe05160', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
